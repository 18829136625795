var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Success",
    {
      attrs: {
        display: _vm.display,
        title: _vm.onboardingSummary.title,
        isOnboarding: "",
        description: _vm.onboardingSummary.description,
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _vm._l(
        _vm.onboardingSummary.onboardingSummaryItems,
        function (onboarding, index) {
          return _c(
            "div",
            {
              key: onboarding + index,
              staticClass: "onboarding-steps",
              class: [
                onboarding.status === _vm.stepStatus.completed.name
                  ? "border-brandPurple"
                  : "incomplete-border",
                onboarding.disabled
                  ? "opacity-50 cursor-not-allowed pointer-events-none"
                  : onboarding.status === _vm.stepStatus.completed.name
                  ? "pointer-events-none"
                  : "cursor-pointer",
              ],
              on: {
                click: function ($event) {
                  return _vm.handleStepToView(onboarding)
                },
              },
            },
            [
              _c("div", { staticClass: "body pr-2" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.iconData[onboarding.key]),
                    },
                  }),
                  _c("div", {}, [
                    _c("p", { staticClass: "text-dark font-semobold" }, [
                      _vm._v(_vm._s(onboarding.name)),
                    ]),
                    _c("p", { staticClass: "text-xs text-grey" }, [
                      _vm._v(" " + _vm._s(onboarding.description) + " "),
                    ]),
                    onboarding.time
                      ? _c("p", { staticClass: "text-grey text-xs" }, [
                          _vm._v(" ~" + _vm._s(onboarding.time) + " "),
                        ])
                      : _vm._e(),
                    _c(
                      "p",
                      { staticClass: "text-xxs text-lightGrey-2 mt-1" },
                      [
                        _c("span", [_vm._v(_vm._s(onboarding.completedSteps))]),
                        _vm._v(" / " + _vm._s(onboarding.numberOfSteps) + " "),
                        onboarding.status === _vm.stepStatus.failed.name ||
                        onboarding.status === _vm.stepStatus.expired.name ||
                        onboarding.status ===
                          _vm.stepStatus.awaitingApproval.name
                          ? _c("Pill", {
                              staticClass: "ml-2 capitalize",
                              attrs: {
                                background: _vm.getPillBackground(
                                  onboarding.status
                                ),
                                color: _vm.getPillTextColor(onboarding.status),
                                radius: "rounded",
                                text: _vm.splitCamelCase(onboarding.status),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "flex items-center justify-end" }, [
                onboarding.status === _vm.stepStatus.completed.name
                  ? _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "13",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "m5.50063 10.5846-3.47-3.47004c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18004c.39.39 1.02.39 1.41 0L16.7906 2.12456c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L5.50063 10.5846Z",
                            fill: "#6956C8",
                          },
                        }),
                      ]
                    )
                  : _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "8",
                          height: "12",
                          fill: "none",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M.7886 1.12457c-.39.39-.39 1.02 0 1.41l3.87999 3.88-3.88 3.88003c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59003c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01Z",
                            fill: "#918E9C",
                          },
                        }),
                      ]
                    ),
              ]),
            ]
          )
        }
      ),
      _c("div", { staticClass: "button-section" }, [
        _c(
          "p",
          {
            staticClass: "text-center text-grey font-semibold cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" Go to Dashboard ")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }