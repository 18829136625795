export const ICON_DATA = {
  SIGN_UP: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
  <rect x="0.5" y="0.5" width="44" height="44" rx="22" fill="#CEE6FD"/>
  <g clip-path="url(#clip0_6095_54629)">
  <path d="M16.1061 16.2403L22.5 13.5L28.8939 16.2403C29.2616 16.3978 29.5 16.7594 29.5 17.1594V24.0181C29.5 26.1712 28.3463 28.1593 26.4768 29.2275L22.5 31.5L18.5232 29.2275C16.6537 28.1593 15.5 26.1712 15.5 24.0181V17.1594C15.5 16.7594 15.7384 16.3978 16.1061 16.2403Z" fill="#1CB0F6" stroke="#1CB0F6" stroke-width="2" stroke-linejoin="round"/>
  <path d="M25.5 20.5L21.5 24.5L19.5 22.5" stroke="#DDF4FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_6095_54629">
  <rect width="24" height="24" fill="white" transform="translate(10.5 10.5)"/>
  </clipPath>
  </defs>
  </svg>`,
  IDENTITY_VERIFICATION: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
  <rect x="0.5" y="0.5" width="44" height="44" rx="22" fill="#FDD9CE"/>
  <g clip-path="url(#clip0_6095_54647)">
  <path d="M14.5 28.5C14.5 26.2909 16.2909 24.5 18.5 24.5H26.5C28.7091 24.5 30.5 26.2909 30.5 28.5C30.5 29.6046 29.6046 30.5 28.5 30.5H16.5C15.3954 30.5 14.5 29.6046 14.5 28.5Z" fill="#FF4B4B" stroke="#FF4B4B" stroke-width="2" stroke-linejoin="round"/>
  <circle cx="22.5" cy="17.5" r="3" fill="#FF7878" stroke="#FF7878" stroke-width="2"/>
  </g>
  <defs>
  <clipPath id="clip0_6095_54647">
  <rect width="24" height="24" fill="white" transform="translate(10.5 10.5)"/>
  </clipPath>
  </defs>
  </svg>`,
  EMPLOYMENT_VERIFICATION: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
  <g>
  <rect x="0.5" y="0.5" width="44" height="44" rx="22" fill="#CFFDCE"/>
  <g clip-path="url(#clip0_6095_54688)">
  <path d="M13.5 15.5H31.5V27.5C31.5 28.6046 30.6046 29.5 29.5 29.5H15.5C14.3954 29.5 13.5 28.6046 13.5 27.5V15.5Z" fill="#58A700" stroke="#58A700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.5 19.5H22.5H31.5" stroke="#58CC02" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
  <path d="M17.5 23.5H20.5" stroke="#D7FFB8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_6095_54688">
  <rect width="24" height="24" fill="white" transform="translate(10.5 10.5)"/>
  </clipPath>
  </defs>
  </svg>`,
  USER_DETAILS_UPDATE: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
  <g>
  <rect x="0.5" y="0.5" width="44" height="44" rx="22" fill="#FDF6CE"/>
  <g clip-path="url(#clip0_6110_45599)">
  <path d="M13.5 27.5V15.5H20.5L22.5 17.5H31.5V27.5C31.5 28.6046 30.6046 29.5 29.5 29.5H15.5C14.3954 29.5 13.5 28.6046 13.5 27.5Z" fill="#CD7900" stroke="#CD7900" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.5 27.5V17.5H31.5V27.5C31.5 28.6046 30.6046 29.5 29.5 29.5H15.5C14.3954 29.5 13.5 28.6046 13.5 27.5Z" fill="#FF9600" stroke="#FF9600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M25.5 21.5L21.5 25.5L19.5 23.5" stroke="#FFF5D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  </g>
  <defs>
  <clipPath id="clip0_6110_45599">
  <rect width="24" height="24" fill="white" transform="translate(10.5 10.5)"/>
  </clipPath>
  </defs>
  </svg>`,
  FINANCIAL_HEALTH: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
  <rect x="0.5" y="0.5" width="44" height="44" rx="22" fill="#CEE6FD"/>
  <g clip-path="url(#clip0_6095_54707)">
  <path d="M16.1061 16.2403L22.5 13.5L28.8939 16.2403C29.2616 16.3978 29.5 16.7594 29.5 17.1594V24.0181C29.5 26.1712 28.3463 28.1593 26.4768 29.2275L22.5 31.5L18.5232 29.2275C16.6537 28.1593 15.5 26.1712 15.5 24.0181V17.1594C15.5 16.7594 15.7384 16.3978 16.1061 16.2403Z" fill="#1CB0F6" stroke="#1CB0F6" stroke-width="2" stroke-linejoin="round"/>
  <path d="M25.5 20.5L21.5 24.5L19.5 22.5" stroke="#DDF4FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0_6095_54707">
  <rect width="24" height="24" fill="white" transform="translate(10.5 10.5)"/>
  </clipPath>
  </defs>
  </svg>`
};
