<template>
  <Success @close="$emit('close')" :display="display" :title="onboardingSummary.title" isOnboarding
    :description="onboardingSummary.description">
    <div class="onboarding-steps" :class="[onboarding.status === stepStatus.completed.name
      ? 'border-brandPurple'
      : 'incomplete-border',
    onboarding.disabled
      ? 'opacity-50 cursor-not-allowed pointer-events-none'
      : onboarding.status === stepStatus.completed.name
        ? 'pointer-events-none'
        : 'cursor-pointer']" v-for="(onboarding, index) in  onboardingSummary.onboardingSummaryItems "
      :key="onboarding + index" @click="handleStepToView(onboarding)">
      <div class="body pr-2">
        <div class="content">
          <div v-html="iconData[onboarding.key]"></div>
          <div class="">
            <p class="text-dark font-semobold">{{ onboarding.name }}</p>
            <p class="text-xs text-grey">
              {{ onboarding.description }}
            </p>
            <p class="text-grey text-xs" v-if="onboarding.time">
              ~{{ onboarding.time }}
            </p>
            <p class="text-xxs text-lightGrey-2 mt-1"><span>{{ onboarding.completedSteps }}</span> / {{
              onboarding.numberOfSteps }}
              <Pill
                v-if="onboarding.status === stepStatus.failed.name || onboarding.status === stepStatus.expired.name || onboarding.status === stepStatus.awaitingApproval.name"
                :background="getPillBackground(onboarding.status)" :color="getPillTextColor(onboarding.status)"
                radius="rounded" class="ml-2 capitalize" :text="splitCamelCase(onboarding.status)" />
            </p>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end">
        <svg v-if="onboarding.status === stepStatus.completed.name" width="18" height="13" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m5.50063 10.5846-3.47-3.47004c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18004c.39.39 1.02.39 1.41 0L16.7906 2.12456c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L5.50063 10.5846Z"
            fill="#6956C8" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="8" height="12" fill="none">
          <path
            d="M.7886 1.12457c-.39.39-.39 1.02 0 1.41l3.87999 3.88-3.88 3.88003c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59003c.39-.39.39-1.02 0-1.41l-4.59-4.59c-.38-.38-1.02-.38-1.41.01Z"
            fill="#918E9C" />
        </svg>

      </div>
    </div>
    <div class="button-section">
      <!-- <Button class="mb-4" text="Go to your dashboard" width="w-full" v-if="completedSteps" @click="$emit('close')" /> -->
      <p class="text-center text-grey font-semibold cursor-pointer" @click="$emit('close')">
        Go to Dashboard
      </p>
    </div>
  </Success>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Button } from "@/UI/Button";
import { Pill } from "@/UI";
import { ICON_DATA } from "./data";
// import { KYC_STEPS, CREDIT_QUALIFICATION_STEPS } from "../data";
import { ONBOARDING_STATUS, KYC_STEPS, CREDIT_QUALIFICATION_STEPS, DASHBOARD_STEPS } from "@/utils/sharedData/home";
export default {
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    customerTodos: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  components: {
    Button,
    Success: () => import("../Success/Success.vue"),
    Pill
  },
  data() {
    return {
      iconData: ICON_DATA,
      stepStatus: ONBOARDING_STATUS,
    }
  },
  computed: {
    ...mapState({
      userData: (state) => state?.auth?.user,
      onboardingSummary: (state) => state?.dashboard?.dashboardData.onboardingSummary,
    }),
    completedSteps() {
      let value;
      if (
        this.customerTodos.some(
          (todo) => todo.key === "IDENTITY_VERIFICATION"
        ) ||
        this.customerTodos.some((todo) => todo.key === "CREDIT_QUALIFICATION")
      ) {
        value = false;
      } else {
        value = true;
      }

      return value;
    },

    titleText() {
      let titleObject = {
        title: "",
        description: "",
      };
      if (this.completedSteps) {
        titleObject.title = `Congratulations`;
        titleObject.description = `Get ready to enjoy the amazing Zilla experience.  `;
      } else {
        titleObject.title = `Let’s verify your identity`;
        titleObject.description = `Complete the following steps to verify your identity.`;
      }
      return titleObject;
    },
  },
  methods: {
    ...mapActions("dashboard", [
      "handleKycStep",
      "showKycStep",
      "handleCreditQualificationStep",
      "showCreditQualificationStep",
      'handleShowPersonalDetails',
      'handleShowReminder'
    ]),
    getPillBackground(key) {
      const status = Object.values(this.stepStatus).find(
        (status) => status.name === key
      );
      if (status) {
        return status.bgColor;
      }
    },
    getPillTextColor(key) {
      const status = Object.values(this.stepStatus).find(
        (status) => status.name === key
      );
      if (status) {
        return status.textColor;
      }
    },
    startKyc() {
      this.$emit("close");
      this.handleKycStep(KYC_STEPS.bvn);
      this.showKycStep(true);
    },
    handleDoneStep(item) {
      let notDone;
      if (item === "REGISTRATION") {
        notDone = false;
      } else {
        notDone = this.customerTodos?.some((todo) => todo.key === item);
      }
      return notDone;
    },
    handleStepToView(step) {
      if (!step.disabled && step.status !== ONBOARDING_STATUS.completed.name) {
        switch (step.key) {
          case  DASHBOARD_STEPS.identityVerification:
            if (this.userData.identityNumberVerified) {
              if (!this.userData.phoneVerified) {
                this.handleKycStep(KYC_STEPS.phone);
              } else if (!this.userData.selfieVerified) {
                this.handleKycStep(KYC_STEPS.camera);
              } else if (!this.userData.addressSorted) {
                this.handleKycStep(KYC_STEPS.address);
              }
            } else {
              this.handleKycStep(KYC_STEPS.bvn);
            }
            this.showKycStep(true);
            break;
          case DASHBOARD_STEPS.employment:
            this.handleCreditQualificationStep(
              CREDIT_QUALIFICATION_STEPS.work
            );
            this.showCreditQualificationStep(true);
            break;
          case DASHBOARD_STEPS.personalDetails:
            this.handleShowPersonalDetails(true);
            break;
          case DASHBOARD_STEPS.financialHealth:
          this.handleCreditQualificationStep(
              CREDIT_QUALIFICATION_STEPS.bank
            );
            this.showCreditQualificationStep(true);
          break;
        }

        this.handleShowReminder(false)
      }
    },
    splitCamelCase(string) {
      const words = string
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_/g, ' ');
      return words.toLowerCase();
    }
  },
};
</script>
<style scoped>
.header {
  height: 8rem;
  background-color: #f6f7ff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.onboarding-steps {
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  height: 6.688rem;
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-top: 8px;
}

.incomplete-border {
  border-color: #c2c0c7;
}

.onboarding-steps .body {
  /* display: grid;
    grid-template-columns: 70% 1fr;
    grid-gap: 10px; */
  width: 100%;
}

.body .content {
  display: grid;
  grid-template-columns: 44px 1fr;
  gap: 10px;
  align-items: center;
}

.button-section {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
</style>
