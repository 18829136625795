var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.handleMore
    ? _c("div", { staticClass: "text-center mt-4" }, [
        _vm.loading
          ? _c("div", { staticClass: "text-center" }, [_c("Spinner")], 1)
          : _c("div", { staticClass: "flex justify-center items-center" }, [
              _c(
                "div",
                {
                  staticClass: "flex items-center cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("paginate")
                    },
                  },
                },
                [
                  _c(
                    "p",
                    { staticClass: "mr-2 font-semibold text-sm md:text-base" },
                    [_vm._v("View more")]
                  ),
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "8",
                        height: "11",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "m1.055.618 5.539 4.914-5.466 4.85",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }