<template>
  <div class="py-0.5 px-2.5 inline-block" :class="[background, radius]">
    <p class="font-semibold text-xs" :class="[text, color]">{{ text }}</p>
  </div>
</template>
<script>
  export default {
    props: {
      text: {
        type: String,
        default: "",
      },
      radius: {
        type: String,
        default: "rounded-xl",
      },
      background: {
        type: String,
        default: "bg-lightPurple",
      },
      color: {
        type: String,
        default: "text-brandPurple",
      },
    },
  };
</script>
